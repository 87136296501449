var msg = new Array();
msg['ERR_PRIVACY']='Devi accettare le condizioni';
msg['ERR_MISSINGFIELDS']='Completa correttamente tutti i campi obbligatori';
msg['ERR_FILLEMAILFIELD']='Compila il campo email';
msg['ERR_USEREXISTS']='Utente già esistente.';
msg['ERR_INVALIDMAIL']='Email non valida';
msg['ERR_CLIENTMANDATORYFIELDS']='I campi contrassegnati sono obbligatori';
msg['ERR_NOSIZESELECTED']='Devi prima selezionare una taglia';
msg['ERR_LOGINREQUIRED']='Devi effettuare l\'accesso';
msg['ERR_LOGININVALID']='Email e/o password non validi';
msg['ERR_CARRIERMISSING']='Devi selezionare un tipo di spedizione';
msg['ERR_CARRIER_COST_MISSING']='Nessuna tariffa applicabile per nazione, corriere e peso dell\'ordine.';
msg['MSG_DEFERRED_DISPATCH_COMPUTATION']='Sarà abilitata la sola prenotazione dell\'ordine e le spese di spedizione verranno comunicate via email';
msg['MSG_ADDEDTOWISHLIST']='Prodotto aggiunto alla lista dei desideri';
msg['MSG_ADDEDTOCART']='Prodotti aggiunti al carrello';
msg['MSG_WAIT']='Attendere...';
msg['MSG_SUBJECT_PRODUCTSHARE']=' ha condiviso qualcosa con te';
msg['MSG_SENDER_PRODUCTSHARE']='Il tuo nome';
msg['MSG_MESSAGE_PRODUCTSHARE']='Messaggio';
msg['MSG_MAILSENT']='E-Mail inviate';
msg['MSG_MIXED_INTANGIBLE']='I prodotti nel carrello non possono essere acquistati insieme al prodotto selezionato.';
msg['MSG_MIXED_CART']='I prodotti nel carrello non possono essere acquistati insieme al prodotto selezionato perchè si trovano in magazzini diversi.';
msg['MSG_INSUFFICIENT_STOCK']='Al momento la disponibilità del prodotto non è sufficiente.';
msg['MSG_APPLIEDVATCHANGED']='Dopo questo aggiornamento dei dati di fatturazione, l\'IVA applicata al tuo ordine è cambiata.';
msg['MSG_GENERIC_ERROR']='Spiacente, si è verificato un errore.';
msg['MSG_COUPON_ERROR']='Codice coupon inserito non valido o inserito precedentemente.';
msg['MSG_COUPON_VALID']='Codice coupon inserito validato con successo.';
msg['MSG_RECOVEREMAILSENT']='Controlla la tua email e clicca sul link per confermare la modifica.';
msg['MSG_RECOVEREMAILSENT_ERROR']='Attenzione: Non sei registrato con questo indirizzo e-mail o stai già reimpostando una nuova password.';
msg['MSG_EXTRAUELIMIT']='L\'ammontare minimo del tuo ordine non può essere inferiore a 2000.00 &euro;';
msg['MSG_RULE_REJECT']='Il prodotto non può essere aggiunto al carrello per via della regola :rule';
msg['LABEL_ARTICLES']='articoli';
msg['LABEL_WARNING']='Attenzione';
msg['LABEL_NOTAPPLICABLE']='Non applicabili';
msg['LABEL_ALREADYREGISTEREDUSERES']='Utenti già registrati';
msg['LABEL_SENTINVITES']='Inviti inviati';
msg['LABEL_NOTSENTINVITES']='Inviti non inviati';
msg['LABEL_INVITES_ALREADY_SENT']='Utenti già invitati';
msg['LABEL_SUBMIT']='Invia';
msg['LABEL_SELECTALL']='Seleziona tutti';
msg['MSG_MESSAGE_SENT']='Messaggio inviato!';
msg['MSG_RETURN_REQUEST_SUCCESS'] = 'Richiesta di reso creata con success';
msg['MSG_SAVE_SUCCESS'] = 'Salvataggio avvenuto con successo';
msg['MSG_SUBSCRIBED'] = 'Iscritto con successo';
msg['MSG_PROFILECONSENT_SAVED'] = 'Le tue preferenze di contatto sono state aggiornate';
msg['wrongpassword'] = 'La password inserita non è corretta';
msg['passwordnotcomplex'] = 'La nuova password non è abbastanza complessa';
msg['MSG_PASSWORD_OK'] = 'La password è stata modificata';
msg['MSG_PASSWORD_NOT_EQUAL'] = 'La due password non corrispondo';
msg['Login to your account'] = 'Utente già esistente';
msg['msg.form.invalidtype'] = 'Si è verificato un errore. Contatta l\'amministrazione riportando quanto segue: "Tipo di contatto non valido o assente."';
msg['msg.form.invalidemail'] = 'Email non valida o mancante';
msg['msg.form.invalidrecipients'] = 'Si è verificato un errore. Contatta l\'amministrazione riportando quanto segue: "Destinatari del contatto non validi o assenti."';

var portal_livestock_available='DISPONIBILE';
var portal_livestock_soldout='ESAURITO';

var activity_online_shop = 'negozio online';
var activity_shop = 'negozio';
var activity_private_sales = 'vendite private';
var activity_outlet = 'outlet';
var activity_online_outlet = 'outlet online';
var activity_store_chain = 'catena di negozi';
var activity_purchasing_group = 'gruppo di acquisto';
var activity_broker = 'broker/agent';
var activity_ebay_amazon = 'negozio ebay/amazon';
var activity_other = 'altro';

var general="General";
var general_personaldata='Dati personali';
var general_olduser='Utente registrato';
var general_newuser='Nuovo utente';
var general_username="Username";
var general_password="Password";
var general_contacts="Contatti";
var general_phone="Telefono";
var general_skype="Skype";
var general_website="WebSite";
var general_fax="Fax";
var general_firstname='Nome';
var general_lastname='Cognome';
var general_company='Azienda';
var general_birth='Data di nascita';
var general_code='Codice';
var general_model='Modello';
var general_quantity='Quantit&agrave;';
var general_availability='Disponibilit&agrave;';
var general_price='Prezzo';
var general_warning='Attenzione';
var general_dispatchto='Destinatario';
var general_careof='C/O';
var general_vat='CF, P.IVA';
var general_address='Indirizzo';
var general_addresstype='Tipo via';
var general_addressnumber='Civico';
var general_city='Citt&agrave;';
var general_prov='Provincia';
var general_zip='CAP';
var general_mobile='Cellulare';
var general_notes='Note';
var general_orders='Ordini';
var general_fgfdiscounts='Amici e sconti';
var general_myprofile='Il mio profilo';
var general_pieces='pz';
var general_size_table='Tabella Taglie';
var general_details='Dettagli';
var general_invoice='Fattura';
var general_creditnote='Nota di credito';
var general_cart= 'Carrello';
var general_country='Nazione';
var general_carrier='Corriere';
var general_dispatch_address='Indirizzo di spedizione';
var general_invoice_address='Indirizzo di fatturazione';
var general_receive_newsletter='Ricevi Newsletter';
var general_copydispatchfrombilldata='Copia dai dati di fatturazione';
var general_instructions="Istruzioni";
var general_add="Aggiungi";
var general_status="Stato";
var general_toomanyfilters="Hai raggiunto il numero massimo di filtri permessi.";

var help_addresstype='Via, Piazza, Corso, ...';
var help_address='Inserire il nome della strada';
var help_dispatchmobile='Inserire un numero di telefono su cui poter essere contattati per la consegna.';
var help_ordercfpiva='Lasciando in bianco questo campo sar&agrave; applicata l\' IVA di default al valore dell\'ordine.';
var help_cf='Inserisci il tuo codice fiscale';

var buttons_save='Salva';
var buttons_changepw='Cambia password';
var buttons_close='Chiudi';
var buttons_continue='Continua';
var buttons_confirm='Conferma';
var buttons_cancel='Annulla';
var buttons_invite='Invita';
var buttons_continueshopping='Continua lo shopping';
var buttons_gocheckout='Vai al carrello';

var messages_product_soldout='Il prodotto &egrave; esaurito';
var messages_noselection='La selezione &egrave; vuota';
var messages_invaliddata='Compilare correttamente tutti i campi';
var messages_recoverpassword='Recupera password';
var messages_recoverpassword_info='Inserisci l\'indirizzo email con cui ti sei registrato. <br/>Ti sar&agrave; inviata un\'email con le istruzioni per impostare una nuova password.';
var messages_invitefriends='Invita gli amici';
var messages_confirmpassword='Conferma password';
var messages_sendingmail='Invio email in corso...';
var messages_mailsent='Email inviate con successo.';
var messages_password='Password';
var messages_confirmpassword='Conferma password';
var messages_requiredfield='Campo obbligatorio';

var subscribe_personaldata='Trattamento dei dati';
var subscribe_privacyintro='Per procedere con l\'iscrizione &egrave; necessario prendere visione della normativa sulla privacy e dare la propria autorizzazione al trattamento dei dati personali.';
var subscribe_privacydisclaimer='Devi accettare l\'informativa sulla privacy per completare la registrazione.'
var subscribe_privacy1='Il sottoscritto attesta di aver preso visione e accettato l\'informativa sulla privacy.';
var subscribe_privacy2='Il sottoscritto autorizza a comunicare i suoi dati a società terze per azioni commerciali (facoltativo)';

var order_status='Stato'
var order_date='Data'
var order_dispatchdate='Dispatched';

var returnedgoods_requests="Richieste di reso";
var returnedgoods_motivations="Motivo del reso";
var returnedgoods_requestcode="Codice reso";
var returnedgoods_requestdate="Richiesto il";
var returnedgoods_motivations_1="Il prodotto non è di mio gradimento";
var returnedgoods_motivations_2="Il prodotto è troppo grande/piccolo";
var returnedgoods_motivations_3="Il prodotto è danneggiato";
var returnedgoods_motivations_4="Il prodotto ricevuto non corrisponde al prodotto richiesto";
var returnedgoods_motivations_5="Il prodotto non è presente nella confezione";
var returnedgoods_motivations_other="Altro";

var i18nOrderStatus = new Array();
i18nOrderStatus[0]='Pendente';
i18nOrderStatus[1]='In pagamento';
i18nOrderStatus[2]='Confermato';
i18nOrderStatus[3]='Spedito';
i18nOrderStatus[4]='Consegnato';
i18nOrderStatus[5]='Prenotato';
i18nOrderStatus[6]='Dropshipping';
i18nOrderStatus[2000]='Annullato';
i18nOrderStatus[2001]='Errore';
i18nOrderStatus[3001]='In lavorazione';
i18nOrderStatus[3002]='Pronto';

i18nOrderStatus[1000]='Payment Cancelled';
i18nOrderStatus[2002]='Verifica pagamento fallita';
i18nOrderStatus[2003]='Rimborsato';
i18nOrderStatus[2005]='Rifiutato';
i18nOrderStatus[3003]='Non spedibile';
i18nOrderStatus[5001]='Non autorizzato';
i18nOrderStatus[5002]='Autorizzato';
i18nOrderStatus[5003]='Dropshipping - Growing';